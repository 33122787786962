<template>
    <div class="documento" >
        <td class="bankLogo">
            <img src="@/assets/viasat/easycob.png" height="50px">
        </td>

        <div class="space2"></div>

        <div class="info2">

            <table>
                <tr>
                    <td>
                        <code>Nome do Cliente</code><br />
                        <b>{{ boleto.nome }}</b> <br>
                    </td>
                    <td>
                        <code>(=) Valor do Documento</code><br />
                        <b>R$ {{ boleto.valor | dinheiro }}</b>
                    </td>
                    <td>
                        <code>Vencimento</code><br />
                        <b> {{ boleto.venc | dataF }}</b>
                    </td>
                </tr>

                <tr>
                    <td>
                        <div class="faturas1"> 
                            <p>
                      <span v-if="mostraFrase">  Boleto bancário referente ao acordo firmando com a EASYCOB, empresa prestadora de serviços da <b>UNISMG</b>.<br></span>    
                      <span>  Para regularizar a dívida descrita abaixo com a UNISMG, é só
                        pagar este boleto até a data
                        de vencimento. <br></span> 
                        <span> Segue abaixo as condições de acordo:<br></span> 
                        <span>Contrato: <b>{{ boleto.contrato }}</b> <br></span> 
                        <span>Parcela <b>{{boleto.parc}} de {{ boleto.totalparc }}</b><br></span> 
                        </p>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <div class="space2"></div>
                        <div class="faturas1">
                            <h4>DEMONSTRATIVO DE FATURA(S): </h4> <br />

                            <p>

                                <span v-for="fat in boleto.faturas" :key="fat.numdoc">
                                    {{ fat.numdoc }} - {{ fat.venc | dataF}} - <b>R$ {{ fat.valor | dinheiro }}</b><br> </span>


                            </p>
                        </div>
                        <br>
                        <br><br>
                        <div class="faturas1">
                            <p>                      
                        NOSSOS MEIOS DE COMUNICAÇÃO: <b>(21) 2206-4868 / 0800-075-0424</b> 
                        <br>
                         E-mail: <b>boleto@easboleto.com.br </b> <br>
                         Whatsapp: <b>(21)2206-4811</b> 
                       </p>

                        </div>
                       
                        

                        

                    </td>
                </tr>
            </table>
        </div>

        <!-- <div class="space"></div> -->
        <div class="boleto">
           
            <!-- <code></code><br /><br /> -->
            <p style="margin: 0;">
                ATENÇÃO: ESTE DOCUMENTO SOMENTE PODERÁ SER PAGO APÓS 24HS DA SUA EMISSÃO, ATÉ A DATA DO VENCIMENTO. <br />
                APÓS O VENCIMENTO ENTRAR EM CONTATO NA CENTRAL DE RELACIONAMENTO DA EASYCOB.<br />
            </p>

            <hr class="hr">
            <table cellspacing="0" cellpadding="0">
                <tr class="topLine">
                    <td class="bankLogo">
                        <img src="@/assets/viasat/bradesco.png" style="padding-bottom: 2px;">
                    </td>
                    <td class="sideBorders center"><span style="font-size:24px;font-weight:bold;">237-2</span></td>
                    <td class="boletoNumber center"><span style="font-size: 16px;">{{ boleto.ld }}</span>
                    </td>
                </tr>
            </table>
            <table cellspacing="0" cellpadding="0" border="1">
                <tr>
                    <td width="90%" colspan="7">
                        <span class="titleed" id="titleed">Local de Pagamento</span>
                        <br />
                        <span class="texto" id="localpagamento">Pagável Preferencialmente na rede Bradesco ou no
                            Bradesco
                            expresso</span>
                    </td>
                    <td width="10%">
                        <span class="titleed" id="titleed">Vencimento</span>
                        <br />
                        <br />
                        <p class="content right texto" style="font-weight:bold;"> {{ boleto.venc | dataF }}</p>
                    </td>
                </tr>
                <tr>
                    <td width="90%" colspan="7">
                        <span class="titleed" id="titleed">Nome do Beneficiário/CNPJ/CPF/Endereço:</span>
                        <br />
                        <table border="0" style="border:none">
                            <tr>
                                <td width="60%"><span class="texto" id="titleed">EASYCOB CONSULTORIA</span></td>
                                <td><span class="texto">CNPJ 01.575.693/0001-03</span></td>
                            </tr>
                        </table>
                        <br />
                        <span class="texto" id="titleed">Av Mal Floriano, 45 sl 201, Centro , Rio de Janeiro, RJ. CEP
                            20080-003</span>
                    </td>
                    <td width="10%" id="agencia">
                        <span class="titleed" id="titleed">Agência/Código do Beneficiário</span>
                        <br />
                        <br />
                        <p class="content right">2761/0008259-7</p>
                    </td>
                </tr>

                <tr>
                    <td width="5%" colspan="2">
                        <span class="titleed" id="titleed">Data do Documento</span>
                        <br />
                        <p class="content center">{{ datahoje | dataF }}</p>
                    </td>
                    <td width="3%" colspan="2">
                        <span class="titleed" id="fonteed">Número do Documento</span>
                        <br />
                        <p class="content center">{{ boleto.nossonum2 }}</p>
                    </td>
                    <td width="6%">
                        <span class="titleed" id="titleed">Espécie Documento</span>
                        <br />
                        <p class="content center">DM</p>
                    </td>
                    <td width="1%">
                        <span class="titleed" id="titleed">Aceite</span>
                        <br />
                        <p class="content center">N</p>
                    </td>
                    <td width="15%">
                        <span class="titleed" id="titleed">Data Processamento</span>
                        <br />
                        <p class="content center">{{ datahoje | dataF }}</p>
                    </td>
                    <td width="15%">
                        <span class="titleed" id="titleed">Carteira/Nosso Número</span>
                        <br />
                        <br />
                        <p class="content right">09/{{ boleto.nossonum }}</p>
                    </td>
                </tr>

                <tr>
                    <td width="10%">
                        <span class="titleed" id="titleed">Uso do Banco</span>
                        <br />
                        <p class="content center"></p>
                    </td>
                    <td width="1%">
                        <span class="titleed" id="titleed">CIP</span>
                        <br />
                        <p class="content center">000</p>
                    </td>
                    <td width="3%">
                        <span class="titleed" id="titleed">Carteira</span>
                        <br />
                        <p class="content center">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;09</p>
                    </td>
                    <td width="3%">
                        <span class="titleed" id="titleed">Moeda</span>
                        <br />
                        <p class="content center">R$</p>
                    </td>
                    <td width="8%" colspan="2">
                        <span class="titleed" id="titleed">Quantidade</span>
                        <br />
                        <p class="content center">N</p>
                    </td>
                    <td width="5%">
                        <span class="titleed" id="titleed">Valor</span>
                        <br />
                        <p class="content center">{{ boleto.valor | dinheiro }}</p>
                    </td>
                    <td width="13%">
                        <span class="titleed" id="titleed"> Valor do Documento</span>
                        <br />
                        <br />
                        <p class="content right">{{ boleto.valor | dinheiro }}</p>
                    </td>
                </tr>
                <tr>
                    <td colspan="7" rowspan="6">
                        <span class="titleed" id="titleed">Instruções de responsabilidade do BENEFICIÁRIO. Qualquer
                            dúvida
                            sobre
                            este boleto contate o beneficiário.</span>
                    </td>

                </tr>
                <tr>
                    <td>
                        <span class="titleed" id="titleed">(-) Descontos/Abatimento</span>
                        <br />
                        <p class="content right">&nbsp;</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span class="titleed" id="titleed"> </span>
                        <br />
                        <p class="content right">&nbsp;</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span class="titleed" id="titleed">(+) Juros/Multa</span>
                        <br />
                        <p class="content right">&nbsp;</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span class="titleed" id="titleed">(+) Outros acréscimos </span>
                        <br />
                        <p class="content right">&nbsp;</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span class="titleed" id="titleed">(=) Valor Cobrado</span>
                        <br />
                        <p class="content right">&nbsp;</p>
                    </td>
                </tr>
                <tr>
                    <td colspan="8">
                        <table border="0" style="border:none">
                            <tr>
                                <td width="60%"><span class="texto" id="titleed"><b>Nome do Pagador: </b>
                                        {{ boleto.nome }}</span>
                                </td>
                                <td><span class="texto"><b>CNPJ/CPF: </b> {{ boleto.cpf | alinhaCPF}}</span></td>
                            </tr>
                            <tr>
                                <td><span class="texto" id="titleed"><b>Endereço: </b> {{ boleto.endereco }}</span></td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td><span class="texto" id="titleed"><b>Sacador/Avalista: </b> EASYCOB
                                        CONSULTORIA</span></td>
                                <td><span class="texto"><b>CNPJ/CPF: </b> 01.575.693/0001-03</span></td>
                            </tr>
                            <div id="iso9001" class="content right">
                                <img src="@/assets/viasat/iso9001.png" height="20px">
                            </div>
                        </table>
                    </td>
                </tr>
            </table>
            <br />

            <div id="code">
                <svg id="barcode"></svg>
            </div>

        </div>
    </div>
</template>

<script>
import JsBarcode from "jsbarcode";
//import barcode from "../../conf/barcode";

export default {
    name: 'UnismgBol2',
    data() {
        return {
            boleto: null,
            datahoje: '99/99/9999',
            mostraFrase: true
        }

    },
    async beforeMount() {
        this.$store.dispatch("insereJaFiz")
        this.$store.dispatch("setMostraBotoes", { imprimir: true, voltar: false });
        this.boleto = this.$store.state.boleto_viasat;
        this.datahoje = this.boleto.databoleto

        
        /*{
            nome: "Paulo André Grillo Pereira",
            cpf: '11640551786',
            contrato: '12349990876',
            valor: "1.00",
            venc: "2023-09-25",
            faturas: [
                { numdoc: "999 88800", venc: "2021-09-23", valor: "1.00" },
                { numdoc: "999 88810", venc: "2021-09-23", valor: "1.00" },
                { numdoc: "999 88820", venc: "2021-09-23", valor: "1.00" },
                { numdoc: "999 88830", venc: "2021-09-23", valor: "1.00" },
                { numdoc: "999 88840", venc: "2021-09-23", valor: "1.00" },
                { numdoc: "999 88850", venc: "2021-09-23", valor: "1.00" },
                { numdoc: "999 88860", venc: "2021-09-23", valor: "1.00" },
                { numdoc: "999 88870", venc: "2021-09-23", valor: "1.00" },
                { numdoc: "999 88880", venc: "2021-09-23", valor: "1.00" },
                { numdoc: "999 88890", venc: "2021-09-23", valor: "1.00" },
                { numdoc: "999 8889z", venc: "2021-09-23", valor: "1.00" },
                { numdoc: "999 8889a", venc: "2021-09-23", valor: "1.00" },
            ],
            cb: "23791948400000001002761090000000000200082590",
            ld: "23792.76104 90000.000001 02000.825907 1 94840000000100",
            nossonum: '00000000002-P',
            nossonum2: '00000000002',
            endereco: "R Padrão 51 Cenira Rio de Janeiro RJ CEP 12345-000",
            parc: '1',
            totalparc: '10',
        }*/

    },
    async mounted() {

        let pacote = this.$store.state.pacote_atual;

        if(pacote=="O-VIASAT" || pacote=="Unismg"){
            this.mostraFrase = false
        }

        JsBarcode("#barcode", this.boleto.cb, {
            format: 'ITF',
            width: 1.107,
            height: 59,
            margin: 0,
            marginTop: 0,
            marginLeft: 20,
            marginBottom: 0,
            displayValue: false,
        });

    },
    filters: {
        dinheiro: function (value) {
            return parseFloat(value)
                .toFixed(2)
                .replace(".", ",");
        },
        dataF: function (d2) {
            let d = d2.substr(0, 10);
            return d.substr(8, 2) + "/" + d.substr(5, 2) + "/" + d.substr(0, 4);
        },
        alinhaCPF: function(cpf){
            let cpfFinal
            if(cpf.length>11){
               cpfFinal =`${cpf.substring(0,2)}.${cpf.substring(2,5)}.${cpf.substring(5,8)}/${cpf.substring(8,12)}-${cpf.substring(12)}`
            }else{
               cpfFinal = `${cpf.substring(0,3)}.${cpf.substring(3,6)}.${cpf.substring(6,9)}-${cpf.substring(9)}`
            }
            return cpfFinal
        },
    }
}


</script>

<style scoped>
* {
    margin: 0;
    font-family: "Arial" !important;
}

.faturas1 h4 {
    margin-bottom: 10px;
}

.faturas1 br {
    margin: 2px 0 !important;
}

.documento {
    margin: auto auto;
    width: 210mm;
    height: 180mm;
    /* 295mm */
    background-color: #fff;
    padding: 20px 10px;
}

.info2 {
    height: 120mm !important;
}


.boleto {
    height: 116mm !important;
}

.space {
    clear: both;
    padding: 5px;
    height: 7cm !important;
    margin: 0 auto;
}

.space1 {
    clear: both;
    padding: 5px;
    height: 2cm !important;
    margin: 0 auto;
}

.space2 {
    clear: both;
    padding: 5px;
    height: 5mm !important;
    margin: 0 auto;
}

#localpagamento {
    font-weight: 600;
    margin-left: 5px;
}

#titleed,
#fonteed {
    margin-left: 5px;
}

#fonteed {
    width: 60px;
}

#iso9001 {
    position: absolute !important;
    right: 0 !important;
}

table {
    width: 100%;
    border-collapse: collapse;
}

td {
    position: relative;
    font-size: 11px;
}

.teste {
    border-right: none !important;
}

.teste2 {
    border-left: none !important;
}


.bankLogo {
    width: 28%;
}

.boletoNumber {
    width: 62%;
    font-weight: bold;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.titleed {
    position: absolute;
    top: 0px;
    font-size: 9px;
    font-weight: bold;
}

.texto {
    font-size: 10px;
}



p.content {
    padding-top: 10px;
    width: fit-content;
    margin: 0;
    font-size: 11px;
}

p {
    font-weight: 500;
}

td p {
    width: 98% !important;
}


.sideBorders {
    border-left: 1px solid black;
    border-right: 1px solid black;
}

.hr {
    size: 1;
    border: 1px dashed;
}

.linha {
    margin-top: 6mm !important;
}

br {
    content: " ";
    display: block;
    margin: 12px 0;
    line-height: 12px;
}

a,
p {
    padding-right: 2rem !important;
}



@media print {

    .no-print,
    .no-print * {
        display: none !important;
    }

    body {
        background-color: #fff;
    }

    .hr2 {
        margin-top: 6mm !important;
    }

    .documento {
        padding: 0 !important;
    }
}</style>